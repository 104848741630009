import layout from '@/layout'

export default {
  path: '/my_insurance',
  component: layout,
  redirect: '/my_insurance/other_insurance_list',
  name: 'my_insurance_other_insurance_list',
  meta: {
    title: 'my_insurance',
    icon: 'el-icon-document'
  },
  children: [
    {
      path: '/my_insurance/other_insurance_list',
      name: 'my_insurance_other_insurance_list',
      component: () => import('@/views/other_insurance/index'),
      meta: {
        title: 'my_insurance_other_insurance_list',
        icon: 'el-icon-document'
      }
    },
    {
      path: '/my_insurance/other_insurance_edit/:id',
      name: 'my_insurance_other_insurance_edit',
      component: () => import('@/views/other_insurance/edit'),
      meta: {
        title: 'my_insurance_other_insurance_edit'
      }
    }
  ]
}
