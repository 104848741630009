import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import en from 'element-plus/lib/locale/lang/en'
import store from '@/store'

// // 解决 ElTable 自动宽度高度导致的「ResizeObserver loop limit exceeded」问题
// const fixElTableErr = (table) => {
//   const oldResizeListener = table.methods.resizeListener
//   table.methods.resizeListener = function () {
//     window.requestAnimationFrame(oldResizeListener.bind(this))
//   }
// }
// // 一定要在Vue.use之前执行此函数
// fixElTableErr(Table)

export default (app) => {
  app.use(ElementPlus, { locale: store.getters.language === 'en' ? en : zhCn })
}
