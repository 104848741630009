<!-- 处理数据，作为最顶层 menu 载体 -->
<template>
  <!-- 一级menu菜单 -->
  <el-menu
    :collapse="!$store.getters.sidebarOpened"
    :default-active="activeMenu"
    :background-color="$store.getters.cssVar.menuBg"
    :text-color="$store.getters.cssVar.menuText"
    :active-text-color="$store.getters.cssVar.menuActiveText"
    :unique-opened="true"
    router
  >
    <!-- 子集菜单 -->
    <!-- <el-submenu index="1">
      <template #title>
        <i class="el-icon-location"></i>
        <span>导航1</span>
      </template>
      <el-menu-item index="1-1">选项1</el-menu-item>
      <el-menu-item index="1-2">选项2</el-menu-item>
    </el-submenu> -->
    <!-- 子集菜单 -->
    <!-- <el-submenu index="2">
      <template #title>
        <i class="el-icon-location"></i>
        <span>导航2</span>
      </template>
      <el-menu-item index="2-1">选项1</el-menu-item>
      <el-menu-item index="2-2">选项2</el-menu-item>
    </el-submenu> -->
    <!-- 具体菜单项 -->
    <!-- <el-menu-item index="4">
      <i class="el-icon-setting"></i>
      <template #title>导航4</template>
    </el-menu-item> -->
    <sidebar-item
      v-for="item in routes"
      :key="item.path"
      :route="item"
    ></sidebar-item>
  </el-menu>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { filterRouters, generateMenus } from '@/utils/router'
import SidebarItem from './SidebarItem.vue'

const router = useRouter()
const routes = computed(() => {
  const fRoutes = filterRouters(router.getRoutes())
  return generateMenus(fRoutes)
})
// 默认激活 根据当前的url进行判断
const route = useRoute()
const activeMenu = computed(() => {
  const { path } = route
  return path
})
</script>

<style lang='scss' scoped>
</style>
