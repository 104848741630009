<!-- tagsView -->
<template>
  <div class="tags-view-container"  @click="handleTagClick" ref="tagsViewContainer">
    <div class="tags-view-list">
      <router-link
        v-for="(tag, index) in $store.getters.tagsViewList"
        :key="tag.fullPath"
        class="tags-view-item"
        :style="{
          backgroundColor: isActive(tag) ? $store.getters.cssVar.menuBg : '',
          borderColor: isActive(tag) ? $store.getters.cssVar.menuBg : '',
        }"
        :class="isActive(tag) ? 'active' : ''"
        :to="{ path: tag.fullPath }"
        @contextmenu.prevent="openMenu($event, index)"
      >
        {{ tag.title }}
        <i
          v-show="!isActive(tag)"
          class="el-icon-close"
          @click.prevent.stop="onCloseClick(index)"
        ></i>
      </router-link>
      <!-- 右键菜单 -->
      <context-menu
        v-show="visible"
        :style="menuStyle"
        :index="selectIndex"
      ></context-menu>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import ContextMenu from './Contextmenu'
import { useStore } from 'vuex'
const route = useRoute()

/**
 * 是否选中
 * @param {*} tag
 */
const isActive = (tag) => {
  return tag && tag.path === route.path
}

const selectIndex = ref(0)
const store = useStore()
/**
 * 关闭 tag 的点击事件
 */
const onCloseClick = (index) => {
  store.commit('app/removeTagsView', {
    type: 'index',
    index: index
  })
}

/**
 * 鼠标右键
 */
const visible = ref(false)
const menuStyle = ref({
  left: 0,
  top: 0
})
const openMenu = (e, index) => {
  const { x, y } = e
  menuStyle.value.left = x + 'px'
  menuStyle.value.top = y + 'px'
  selectIndex.value = index
  visible.value = true
}

const closeMenu = () => {
  visible.value = false
}

watch(visible, (val) => {
  if (val) {
    document.body.addEventListener('click', closeMenu)
  } else {
    document.body.removeEventListener('click', closeMenu)
  }
})

const handleTagClick = (event) => {
  const target = event.target
  if (target.classList.contains('tags-view-item')) {
    const isHidden = isElementHidden(target)
    if (isHidden) {
      showHiddenElement(target)
    }
  }
}
const tagsViewContainer = ref(null)

const isElementHidden = (element) => {
  const rect = element.getBoundingClientRect()
  return (
    rect.left < 210 ||
    rect.right > (tagsViewContainer.value.clientWidth || window.innerWidth)
  )
}

const showHiddenElement = (element) => {
  const left = element.getBoundingClientRect().left
  console.log('🚀 ~ file: index.vue:110 ~ showHiddenElement ~ left:', left)
  if (left > 210) {
    tagsViewContainer.value.scrollBy({ left: element.offsetWidth, behavior: 'smooth' })
  } else {
    tagsViewContainer.value.scrollBy({ left: -element.offsetWidth, behavior: 'smooth' })
  }
}

</script>

<style lang="scss" scoped>
.tags-view-container {
  height: 34px;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #d8dce5;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
  overflow-x: hidden;
  white-space: nowrap; /* 添加这行 */
  .tags-view-list {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    .tags-view-item {
      flex-shrink: 0;
      display: inline-block; /* 添加这行 */

      position: relative;
      cursor: pointer;
      height: 26px;
      line-height: 26px;
      border: 1px solid #d8dce5;
      border-radius: 4px;
      color: #495060;
      background: #fff;
      padding: 0 8px;
      font-size: 12px;
      margin-left: 5px;
      margin-top: 4px;
      &:first-of-type {
        margin-left: 15px;
      }
      &:last-of-type {
        margin-right: 15px;
      }
      &.active {
        color: #fff;
        &::before {
          content: "";
          background: #fff;
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          position: relative;
          margin-right: 4px;
        }
      }
      // close 按钮
      .el-icon-close {
        width: 16px;
        height: 16px;
        line-height: 10px;
        vertical-align: 2px;
        border-radius: 50%;
        text-align: center;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transform-origin: 100% 50%;
        &:before {
          transform: scale(0.6);
          display: inline-block;
          vertical-align: -3px;
        }
        &:hover {
          background-color: #b4bccc;
          color: #fff;
        }
      }
    }
  }
}
</style>
