import { getItem, setItem } from '@/utils/storage'
import { LANG, TAGS_VIEW } from '@/constant'

export default {
  namespaced: true,
  state: () => ({
    sidebarOpened: true,
    language: getItem(LANG) || 'zh',
    tagsViewList: getItem(TAGS_VIEW) || []
  }),
  mutations: {
    triggerSidebarOpened (state) {
      state.sidebarOpened = !state.sidebarOpened
    },
    setLanguage (state, lang) {
      setItem(LANG, lang)
      state.language = lang
    },
    // 添加 tags
    addTagsViewList (state, tag) {
      // 处理重复的
      const isFind = state.tagsViewList.find(item => {
        return item.path === tag.path
      })
      // 处理重复
      if (!isFind) {
        state.tagsViewList.push(tag)
        setItem(TAGS_VIEW, state.tagsViewList)
      }
    },
    // 为指定的tag 修改 title
    changeTagsView (state, { index, tag }) {
      state.tagsViewList[index] = tag
      setItem(TAGS_VIEW, state.tagsViewList)
    },
    // 移除 tags payload {type: 'system' \ 'right' \ 'index'}
    removeTagsView (state, payload) {
      if (payload.type === 'index') {
        state.tagsViewList.splice(payload.index, 1)
      } else if (payload.type === 'system') {
        // state.tagsViewList.splice(payload.index + 1, state.tagsViewList.length - payload.index + 1)
        // state.tagsViewList.splice(payload.index + 1, state.tagsViewList.length - payload.index + 1)
        const temp1 = []
        for (let j = payload.index; j < state.tagsViewList.length; j++) {
          temp1.push(state.tagsViewList[j])
        }
        const right = temp1.filter((item, index) => item.fullPath === payload.route || index === 0)
        const temp2 = []
        for (let i = 0; i < payload.index; i++) {
          temp2.push(state.tagsViewList[i])
        }
        const left = temp2.filter((item, index) => item.fullPath === payload.route)
        state.tagsViewList = [...left, ...right]
        // state.tagsViewList.splice(0, payload.index)
      } else if (payload.type === 'right') {
        const temp = []
        for (let i = payload.index + 1; i < state.tagsViewList.length; i++) {
          temp.push(state.tagsViewList[i])
        }
        const temp1 = temp.filter((item, index) => item.fullPath === payload.route) || []
        state.tagsViewList.splice(payload.index + 1, state.tagsViewList.length - payload.index + 1)
        if (temp1.length > 0) {
          state.tagsViewList.push(...temp1)
        }
      }
      setItem(TAGS_VIEW, state.tagsViewList)
    },

    // 移除标签根据 fullPath
    removeTagsByFullPath (state, fullPath) {
      const index = state.tagsViewList.findIndex(item => item.fullPath === fullPath)
      state.tagsViewList.splice(index, 1)
      setItem(TAGS_VIEW, state.tagsViewList)
    }
  },
  actions: {}
}
