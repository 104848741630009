<!--  -->
<template>
  <div
    class="app-wrapper"
    :class="[$store.getters.sidebarOpened ? 'openSidebar' : 'hideSidebar']"
  >
    <!-- 左侧 menu -->
    <sidebar
      id="guide-sidebar"
      class="sidebar-container"
      :style="{ backgroundColor: $store.getters.cssVar.menuBg }"
    ></sidebar>
    <div class="main-container">
      <div class="fixed-header">
        <!-- 顶部的 navbar -->
        <navbar />
        <!-- tags -->
        <tags-view id="guide-tags"></tags-view>
      </div>
      <!-- 内容区 -->
      <app-main />
    </div>
  </div>
</template>

<script setup>
import {} from 'vue'
import Navbar from './components/Navbar'
import Sidebar from './components/Sidebar'
import AppMain from './components/AppMain'
import TagsView from '@/components/TagsView'
import { ElMessageBox } from 'element-plus'
import { noticeReject } from '@/api/notice'

const noticeTap = async () => {
  const res = await noticeReject()
  if (res.status === 1) {
    const message = `
      保单驳回数： <span style="color:${res.data.order_num > 0 ? 'red' : ''} ">${res.data.order_num}</span> 单 <br />
      发票驳回数： <span style="color:${res.data.invoice_num > 0 ? 'red' : ''} ">${res.data.invoice_num}</span> 单 <br />
      行驶证驳回数： <span style="color:${res.data.driving_card_num > 0 ? 'red' : ''} ">${res.data.driving_card_num}</span> 单 <br />
      身份证驳回数： <span style="color:${res.data.id_card_num > 0 ? 'red' : ''} ">${res.data.id_card_num}</span> 单 <br />
      批次驳回数： <span style="color:${res.data.master_num > 0 ? 'red' : ''} ">${res.data.master_num}</span> 单 <br />
    `
    ElMessageBox.alert(message, res.message, {
      dangerouslyUseHTMLString: true
    })
  }
}

noticeTap()

</script>

<style lang='scss' scoped>
@import '~@/styles/mixin.scss';
@import '~@/styles/variables.module.scss';
.fixed-header {
  position: fixed;
  top:0;
  right:0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width #{$sideBarDuration};
}

.hideSidebar .fixed-header {
  width: calc(100% - #{$hideSideBarWidth});
}
</style>
