import request from '@/utils/request'

/**
 * 生成用于登录的二维码
 * @param {*} data
 * @returns
 */
export const WeChatScanQRCode = data => {
  return request({
    url: '/WeChatScanQRCode',
    method: 'POST',
    data
  })
}

/**
 * 生成用于绑定的二维码
 * @param {*} data
 * @returns
 */
export const WeChatScanBindQRCode = data => {
  return request({
    url: '/WeChatScanBindQRCode',
    method: 'POST',
    data
  })
}

/**
 * 扫码登录
 * @param {*} data
 * @returns
 */
export const WeChatScanLogin = data => {
  return request({
    url: '/WeChatScanLogin',
    method: 'POST',
    data
  })
}

/**
 * 绑定微信
 * @param {*} data
 * @returns
 */
export const WeChatBind = data => {
  return request({
    url: '/WeChatBind',
    method: 'POST',
    data
  })
}
