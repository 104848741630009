import layout from '@/layout'

export default {
  path: '/my_insurance',
  component: layout,
  redirect: '/my_insurance/create_insurance_list',
  name: 'my_create_insurance',
  meta: {
    title: 'my_insurance',
    icon: 'el-icon-document'
  },
  children: [
    {
      path: '/my_insurance/create_insurance_list',
      name: 'my_create_insurance',
      component: () => import('@/views/insurance/myCreateInsuranceList'),
      meta: {
        title: 'my_create_insurance',
        icon: 'el-icon-document'
      }
    },
    {
      path: '/my_insurance/create_insurance_list/:id',
      name: 'my_create_insurance_detail',
      component: () => import('@/views/insurance/myCreateInsuranceDetail'),
      meta: {
        title: 'my_create_insurance_detail'
      }
    }
  ]
}
