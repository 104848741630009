import layout from '@/layout'

export default {
  path: '/other_insurance',
  component: layout,
  redirect: '/other_insurance/stages',
  name: 'other_insurance_stages',
  meta: {
    title: 'other_insurance_stages',
    icon: 'el-icon-document'
  },
  children: [
    {
      path: '/other_insurance/stages',
      name: 'other_insurance_stages',
      component: () => import('@/views/other_order_stages/index'),
      meta: {
        title: 'other_insurance_stages',
        icon: 'el-icon-document'
      }
    }
  ]
}
