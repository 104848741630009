import { generateColors } from '../utils/theme'
import { getItem } from '@/utils/storage'
import { MAIN_COLOR } from '@/constant'

// 快捷访问
const getters = {
  /**
   * token
   * @param {*} state
   * @returns
   */
  token: state => state.admin.token,

  /**
   * @return true 表示已经存在用户信息
   */
  hasAdminInfo: state => {
    return JSON.stringify(state.admin.adminInfo) !== '{}'
  },

  /**
   * 获取当前的账套信息
   * @param {*} state
   * @returns
   */
  currentAccountId: state => state.account.currentAccountId,

  /**
   * 当前账套名称
   * @param state
   * @returns {any}
   */
  currentAccountName: state => state.account.currentAccountName,

  /**
   * 管理员信息
   * @param {*} state
   * @returns
   */
  adminInfo: state => state.admin.adminInfo,

  /**
   * 定义的颜色
   * @param {*} state
   * @returns
   */
  cssVar: state => ({
    ...state.theme.variables,
    ...generateColors(getItem(MAIN_COLOR))
  }),

  /**
   * 左侧菜单栏是否展开
   */
  sidebarOpened: state => state.app.sidebarOpened,

  /**
   * 语言 国际化
   */
  language: state => state.app.language,

  /**
   * 主题颜色
   */
  mainColor: state => state.theme.main_color,

  /**
   * tags
   */
  tagsViewList: state => state.app.tagsViewList

}

export default getters
