import layout from '@/layout'

export default {
  path: '/reject',
  component: layout,
  redirect: '/reject/insurance',
  name: 'rejectInsurance',
  meta: {
    title: 'reject',
    icon: 'el-icon-files'
  },
  children: [
    {
      path: '/reject/insurance',
      name: 'rejectInsurance',
      component: () => import('@/views/reject/insurance'),
      meta: {
        title: 'rejectInsurance',
        icon: 'el-icon-files'
      }
    }, {
      path: '/reject/insuranceEdit/:type/:id',
      name: 'rejectInsuranceEdit',
      component: () => import('@/views/reject/edit'),
      meta: {
        title: 'rejectInsuranceEdit'
      }
    }
  ]
}
