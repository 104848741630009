/**
 * 封装 请求
 */
import axios from 'axios'
import { ElMessage } from 'element-plus'
import store from '@/store'
import ProjectConfig from '@/config'

const server = axios.create({
  baseURL: process.env.VUE_APP_BASE_API + process.env.VUE_APP_API_VERSION,
  timeout: 50000
})

// 请求拦截器
server.interceptors.request.use(
  config => {
    // 在这个位置上统一注入 token
    if (store.getters.token) {
      config.headers.token = `${store.getters.token}`
    }
    config.headers.project = ProjectConfig.project_id
    // 必须返回 config
    return config
  }
)

// 响应拦截器
server.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    if (error.response && error.response.data && error.response.data.code === 401) {
      // ! token 超时
      store.dispatch('admin/logout')
      ElMessage.error(error.response.data.message)
    } else if (error.response && error.response.data && error.response.data.code === 402) {
      return error.response.data
    } else {
      ElMessage.error(error.message)
    }
    return Promise.reject(error)
  }
)

export default server
