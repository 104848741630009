import { getItem, setItem, removeItem } from '@/utils/storage'
import { loginMobile } from '@/api/login'
import { adminInfo } from '@/api/admin'
import { WeChatScanLogin } from '@/api/we_chat_scan_login'
import { TOKEN } from '@/constant'
import router, { resetRouter } from '@/router'

export default {
  namespaced: true,
  state: () => ({
    token: getItem(TOKEN) || '',
    adminInfo: {}
  }),
  mutations: {
    setToken (state, token) {
      state.token = token
      setItem(TOKEN, token)
    },
    setAdminInfo (state, adminInfo) {
      state.adminInfo = adminInfo
    }
  },
  actions: {
    /**
     * 登录
     * @param {*} context
     * @param {*} userInfo
     * @returns
     */
    login (context, userInfo) {
      const {
        mobile,
        code
      } = userInfo
      return new Promise((resolve, reject) => {
        loginMobile({
          mobile,
          code
        }).then(res => {
          res.status === 1 && this.commit('admin/setToken', res.data)
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },

    loginWx (context, code) {
      return new Promise((resolve, reject) => {
        WeChatScanLogin({
          code: code
        }).then(res => {
          res.status === 1 && this.commit('admin/setToken', res.data)
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },

    /**
     * 获取管理员信息
     * @param {*} context
     * @returns
     */
    async getAdminInfo (context) {
      const res = await adminInfo()
      this.commit('admin/setAdminInfo', res.data)
      return res
    },
    /**
     * 退出
     */
    logout () {
      resetRouter()
      this.commit('admin/setToken', '')
      this.commit('admin/setAdminInfo', {})
      this.commit('account/setCurrentAccountId', {
        id: 0,
        name: ''
      })
      removeItem('token')
      removeItem('tagsView')
      const domain = window.location.origin
      window.location.replace(domain)
      router.push('/login')
    },

    /**
     * 重新选择账套
     */
    accountIndex () {
      router.push('/')
    }
  }
}
