import { createRouter, createWebHashHistory } from 'vue-router'
import layout from '@/layout'
import permissionAdmin from './modules/permission/permissionAdmin'
import permissionRole from './modules/permission/permissionRole'
import permissionMark from './modules/permission/permissionMark'
// import AdminLog from './modules/other/AdminLog'
import orderSubmit1 from './modules/insurance/orderSubmit1'
import orderSubmit2 from './modules/insurance/orderSubmit2'
import orderSubmit3 from './modules/insurance/orderSubmit3'
import orderSubmit4 from './modules/insurance/orderSubmit4'
import orderSubmit5 from './modules/insurance/orderSubmit5'
import orderSubmit6 from './modules/insurance/orderSubmit6'
import otherOrderSubmitFree from './modules/other_insurance/submitFree'
import myCreateInsuranceList from './modules/insurance/myCreateInsuranceList'
import myBelongAreaList from './modules/insurance/myBelongAreaList'
import otherInsuranceSubmit from './modules/other_insurance/submit'
import myOtherInsuranceList from './modules/other_insurance/myOtherInsuranceList'
import supplementPage1 from './modules/supplement/page1'
import supplementPage2 from './modules/supplement/page2'
import supplementPage3 from './modules/supplement/page3'
import supplementPage4 from './modules/supplement/page4'
import supplementPage5 from './modules/supplement/page5'
import supplementPage6 from './modules/supplement/page6'
import supplementPage7 from './modules/supplement/page7'
import supplementPage8 from './modules/supplement/page8'
import supplementPage9 from './modules/supplement/page9'
import supplementPage10 from './modules/supplement/page10'
import rejectInsurance from './modules/reject/insurance'
import rejectInvoice from './modules/reject/invoice'
import rejectDrivingCard from './modules/reject/drivingCard'
import rejectIdCard from './modules/reject/idCard'
import RejectMasterList from './modules/reject/master-list'
import advanceFundApply from './modules/advance_fund/apply'
import otherOtherAdvanceFundApply from './modules/other_order_advance_fund/apply'
import teamOrderAdvanceFund from './modules/team_order_advance_fund/apply'
import carOrderAdvanceFund from './modules/car_order_advance_fund/apply'
import CarPage1 from './modules/car/page1'
import CarPage2 from './modules/car/page2'
import CarPage3 from './modules/car/page3'
import MyOtherOrderStages from './modules/other_order_stages'
import Policy from './modules/policy/index'
import InvoiceBindCar from './modules/car_invoice_bind'
import BossWorkPersonPerformance from './modules/person/performance/index'
import BossWorkPersonReport from './modules/person/report/index'
// import test from './modules/test'
import store from '@/store'

/**
 * 私有路由表 需要有权限才能访问
 */
export const privateRoutes = [
  orderSubmit1,
  orderSubmit2,
  orderSubmit3,
  orderSubmit4,
  orderSubmit5,
  orderSubmit6,
  otherOrderSubmitFree,
  otherInsuranceSubmit,
  myCreateInsuranceList,
  myBelongAreaList,
  myOtherInsuranceList,
  MyOtherOrderStages,
  supplementPage7,
  supplementPage8,
  supplementPage9,
  supplementPage10,
  supplementPage1,
  supplementPage2,
  supplementPage3,
  supplementPage4,
  supplementPage5,
  supplementPage6,
  RejectMasterList,
  rejectInsurance,
  rejectInvoice,
  rejectDrivingCard,
  rejectIdCard,
  advanceFundApply,
  carOrderAdvanceFund,
  teamOrderAdvanceFund,
  otherOtherAdvanceFundApply,
  InvoiceBindCar,
  Policy,
  CarPage1,
  CarPage2,
  CarPage3,
  BossWorkPersonPerformance,
  BossWorkPersonReport,
  // 管理员列表
  permissionAdmin,
  // 角色管理
  permissionRole,
  // 权限管理
  permissionMark
  // 日志
  // AdminLog
  // test
]
/**
 * 公开路由表 不需要登录 就能访问的页面
 */
export const publicRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index')
  },
  {
    path: '/bindWx',
    component: () => import('@/views/login/bindwx')
  },
  {
    path: '/',
    // 注意：带有路径“/”的记录中的组件“默认”是一个不返回 Promise 的函数
    component: layout,
    redirect: '/index',
    children: [
      // 个人中心
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/index/index'),
        meta: {
          title: 'profile',
          icon: 'el-icon-house'
        }
      },
      // 404 页面
      {
        path: '/404',
        name: '404',
        component: () => import('@/views/error_page/404')
      },
      // 401 页面
      {
        path: '/401',
        name: '401',
        component: () => import('@/views/error_page/401')
      }
    ]
  }

]

export function resetRouter () {
  if (store.getters.adminInfo && store.getters.adminInfo.permission && store.getters.adminInfo.permission.menus) {
    const menus = store.getters.adminInfo.permission.menus

    menus.forEach(menu => {
      router.removeRoute(menu)
    })
  }
}

const router = createRouter({
  history: createWebHashHistory(),
  routes: publicRoutes
})

export default router
