import layout from '@/layout'

export default {
  path: '/supplement',
  component: layout,
  redirect: '/supplement/page4',
  name: 'supplementPage4',
  meta: {
    title: 'supplement',
    icon: 'el-icon-document'
  },
  children: [
    {
      path: '/supplement/page4',
      name: 'supplementPage4',
      component: () => import('@/views/supplement/page4'),
      meta: {
        title: 'supplementPage4',
        icon: 'el-icon-document'
      }
    }
  ]
}
