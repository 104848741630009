import request from '@/utils/request'
const apiPreQuest = '/admin'
/**
 * 管理员信息
 */
export const adminInfo = data => {
  return request({
    url: `${apiPreQuest}Info`,
    method: 'POST',
    data
  })
}
/**
 * 管理员列表
 * @param {*} data
 * @returns
 */
export const allData = data => {
  return request({
    url: `${apiPreQuest}All`,
    method: 'POST',
    data
  })
}

export const index = data => {
  return request({
    url: `${apiPreQuest}Index`,
    method: 'POST',
    data
  })
}

export const add = data => {
  return request({
    url: `${apiPreQuest}Add`,
    method: 'POST',
    data
  })
}

export const edit = data => {
  return request({
    url: `${apiPreQuest}Edit`,
    method: 'POST',
    data
  })
}

export const changeStatus = data => {
  return request({
    url: `${apiPreQuest}ChangeStatus`,
    method: 'POST',
    data
  })
}

export const del = data => {
  return request({
    url: `${apiPreQuest}Del`,
    method: 'POST',
    data
  })
}

export const detail = data => {
  return request({
    url: `${apiPreQuest}Detail`,
    method: 'POST',
    data
  })
}

/**
 * 平台管理员列表
 * @param {*} data
 * @returns
 */
export const adminProject = data => {
  return request({
    url: `${apiPreQuest}ProjectIndex`,
    method: 'POST',
    data
  })
}

/**
 * 平台管理员新增
 * @param {*} data
 * @returns
 */
export const adminAddProject = data => {
  return request({
    url: 'addAdminProject',
    method: 'POST',
    data
  })
}

/**
 * 平台管理员详情
 * @param {*} data
 * @returns
 */
export const showAdminProjectDetail = data => {
  return request({
    url: 'showAdminProjectDetail',
    method: 'POST',
    data
  })
}

/**
 * 编辑管理员数据
 * @param {*} data
 * @returns
 */
export const editAdminProject = data => {
  return request({
    url: 'editAdminProject',
    method: 'POST',
    data
  })
}

/**
 *
 * @param {*} data
 * @returns
 */
export const adminProjectDel = data => {
  return request({
    url: 'adminProjectDel',
    method: 'POST',
    data
  })
}
