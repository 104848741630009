import layout from '@/layout'

export default {
  path: '/reject',
  component: layout,
  redirect: '/reject/idCard',
  name: 'rejectIdCard',
  meta: {
    title: 'reject',
    icon: 'el-icon-files'
  },
  children: [
    {
      path: '/reject/idCard',
      name: 'rejectIdCard',
      component: () => import('@/views/reject/idCard'),
      meta: {
        title: 'rejectIdCard',
        icon: 'el-icon-files'
      }
    }
  ]
}
