import layout from '@/layout'
export default {
  path: '/invoice_bind',
  component: layout,
  redirect: '/invoice_bind/car',
  name: 'InvoiceBindCar',
  meta: {
    title: 'InvoiceBind',
    icon: 'el-icon-document'
  },
  children: [
    {
      path: '/invoice_bind/car',
      name: 'InvoiceBindCar',
      component: () => import('@/views/car_invoice_bind/index'),
      meta: {
        title: 'InvoiceBindCar',
        icon: 'el-icon-document'
      }
    }
  ]
}
