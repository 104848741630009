import layout from '@/layout'

export default {
  path: '/supplement',
  component: layout,
  redirect: '/supplement/page8',
  name: 'supplementPage8',
  meta: {
    title: 'supplement',
    icon: 'el-icon-document'
  },
  children: [
    {
      path: '/supplement/page8',
      name: 'supplementPage8',
      component: () => import('@/views/supplement/page8'),
      meta: {
        title: 'supplementPage8',
        icon: 'el-icon-document'
      }
    },
    {
      path: '/supplement/order_detail/:id',
      name: 'supplementOrderDetail',
      component: () => import('@/views/supplement/order_detail'),
      meta: {
        title: 'supplementOrderDetail'
      }
    }
  ]
}
