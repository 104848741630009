import layout from '@/layout'

export default {
  path: '/supplement',
  component: layout,
  redirect: '/supplement/page1',
  name: 'supplementPage1',
  meta: {
    title: 'supplement',
    icon: 'el-icon-document'
  },
  children: [
    {
      path: '/supplement/page1',
      name: 'supplementPage1',
      component: () => import('@/views/supplement/page1'),
      meta: {
        title: 'supplementPage1',
        icon: 'el-icon-document'
      }
    }
  ]
}
