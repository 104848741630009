<!--  -->
<template>
  <div class="cc" style="">
    <div class="logo-container">
      <pan-thumb :image="$store.getters.adminInfo.pic">
        <div
          style="
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            height: 60px;
            font-size: 12px;
          "
        >
          <span style="display: inline-block">{{
            $store.getters.adminInfo.user_name
          }}</span>
          <span style="display: inline-block">{{
            $store.getters.adminInfo.admin_role_name
          }}</span>
        </div>
      </pan-thumb>
      <h1 class="logo-title" v-if="$store.getters.sidebarOpened"  style="margin-top: 20px">
        诚信保单录入系统
      </h1>
    </div>
    <el-scrollbar style="box-sizing: border-box;flex:1">
      <sidebar-menu></sidebar-menu>
    </el-scrollbar>
  </div>
</template>

<script setup>
import {} from 'vue'
import SidebarMenu from './SidebarMenu'
import PanThumb from '@/components/PanThumb'

const logoHeight = 44
</script>

<style lang="scss" scoped>
.cc {
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .logo-container {
    height: v-bind(logoHeight) + "px";
    padding: 20px 0 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    .logo-title {
      color: #fff;
      font-weight: 600;
      // line-height: 50px;
      font-size: 16px;
      white-space: nowrap; /* 不换行 */
      text-shadow: 2px 2px 4px #000000;
    }

    .logo-title:hover {
      animation: scale 1s infinite;
    }

    @keyframes rotate3d {
      0% {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
      }
      50% {
        transform: rotateX(360deg) rotateY(180deg) rotateZ(90deg);
      }
      100% {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
      }
    }

    @keyframes shake {
      0% {
        transform: translateX(0);
      }
      25% {
        transform: translateX(-5px);
        transform: translateY(5px);
      }
      75% {
        transform: translateX(5px);
        transform: translateY(-5px);
      }
      100% {
        transform: translateX(0);
      }
    }

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes typing {
      0% {
        width: 0%;
      }
      100% {
        width: 100%;
      }
    }

    @keyframes scale {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1);
      }
    }

    @keyframes blink {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .el-avatar {
      --el-avatar-background-color: none;
      margin-right: 12px;
    }
  }
}
</style>
