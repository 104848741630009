<template>
  <el-breadcrumb class="breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <!-- 面包屑的最后一项 -->
      <el-breadcrumb-item v-for="(item,index) in breadcrumData" :key="item.path">
        <!--  不可点击    -->
        <span class="no-redirect" v-if="index === breadcrumData.length -1">{{ generateTitle(item.meta.title) }}</span>
        <!--  可以点击    -->
        <span class="redirect" v-else @click="onLinkClick(item)">{{ generateTitle(item.meta.title) }}</span>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { generateTitle } from '@/utils/i18n'

// 生成数组数据
const breadcrumData = ref([])
const getBreadcrumData = () => {
  breadcrumData.value = route.matched.filter(item => item.meta && item.meta.title)
}

// watch 监听
const route = useRoute()
watch(route, () => {
  getBreadcrumData()
}, {
  immediate: true
})

// 跳转事件
const router = useRouter()
const onLinkClick = (item) => {
  router.push(item.path)
}

// 需要主题 替换 所以 hover 的颜色 设置成项目的主色
const store = useStore()
const linkHoverColor = ref(store.getters.cssVar.menuBg)

</script>

<style lang="scss" scoped>
.breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;

  .redirect {
    color: #666;
    font-weight: 600;
    cursor: pointer;
  }

  .redirect:hover {
    color: v-bind(linkHoverColor);
  }

  ::v-deep .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>
