import router from './router'
import store from '@/store'

// 无需鉴权 白名单
const whiteList = ['/login', '/bindWx']

/**
 * 路由前置守卫
 * @param to 要到哪里去
 * @param from 你从哪里来
 * @param next 是否要去？
 */
router.beforeEach(async (to, from, next) => {
  if (store.getters.token) {
    // 1. 用户已登录 不能进入 login
    if (to.path === '/login') {
      next('/')
    } else {
      // 判断用户资料是否获取
      // 如果不存在用户信息  则需要获取用户信息
      if (!store.getters.hasAdminInfo) {
        // 判断用户资料是否存在 如果不存在 就需要获取用户信息
        const res = await store.dispatch('admin/getAdminInfo')
        const {
          permission
        } = res.data
        const isSupper = res.data.is_supper
        // 处理用户权限，筛选出 需要添加的路由
        const filterRoutes = await store.dispatch('permission/filterRouters', {
          menus: permission.menus,
          isSupper: isSupper
        })
        // 循环添加动态路由
        filterRoutes.forEach(item => {
          router.addRoute(item)
        })
        // 添加完动态路由 需要进行以此主动跳转 才能生效
        return next(to.path)
      }
      next()
    }
  } else {
    // 没有token的情况 可以进入白名单
    if (whiteList.indexOf(to.path) > -1) {
      next()
    } else {
      next('/login')
    }
  }
})
