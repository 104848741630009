<template>
  <div class="navbar">
    <hamburger class=" "/>
    <breadcrumb class="breadcrumb-container" id="guide-breadcrumb"></breadcrumb>
    <div class="right-menu">
      <!-- 账套首页 -->
      <switch-account-index class="right-menu-item hover-effect"></switch-account-index>
      <!-- 引导 -->
      <guide class="right-menu-item hover-effect" />
      <!-- 页面搜索 -->
      <header-search class="right-menu-item hover-effect"></header-search>
      <!-- 全屏显示 -->
      <screenfull class="right-menu-item hover-effect"></screenfull>
      <!-- 切换主题 -->
      <theme-select class="right-menu-item hover-effect"></theme-select>

      <!-- 头像 -->
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <el-avatar
            shape="square"
            :size="40"
            :src="require('@/assets/logo.png')"
          ></el-avatar>
          <i class="el-icon-s-tools"></i>
        </div>
        <template #dropdown>
          <el-dropdown-menu class="user-dropdown">
            <router-link to="/">
              <el-dropdown-item> 首页 </el-dropdown-item>
            </router-link>

            <el-dropdown-item divided @click="logout">
              {{ $t('msg.navBar.logout') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script setup>
import {} from 'vue'
import { useStore } from 'vuex'
import Hamburger from '@/components/hamburger'
import Breadcrumb from '@/layout/components/Breadcrumb/index'
import ThemeSelect from '@/components/ThemeSelect/index'
import SwitchAccountIndex from '@/components/SwitchAccountIndex/index'
import Screenfull from '@/components/Screenfull/index'
import HeaderSearch from '@/components/HeaderSearch/index'
import Guide from '@/components/Guide/index'

const store = useStore()

/**
 * 退出
 */
const logout = () => {
  store.dispatch('admin/logout')
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .breadcrumb-container {
    float: left;
  }

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    margin-left: 10px;
    cursor: pointer;
    // hover 动画
    transition: background 0.5s;

    &:hover {
      background: rgba(0, 0, 0, 0.1)
    }
  }

  .right-menu {
    display: flex;
    align-items: center;
    float: right;
    padding-right: 16px;

    ::v-deep .right-menu-item {
      display: inline-block;
      padding: 0 18px 0 0;
      font-size: 24px;
      color: #5a5e66;
      vertical-align: text-bottom;
    }

    &.hover-effect {
      cursor: pointer;
    }

    ::v-deep .avatar-container {
      cursor: pointer;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .el-avatar {
          --el-avatar-background-color: none;
          margin-right: 12px;
        }
      }
    }
  }
}
</style>
