import request from '@/utils/request'

/**
 * 发送短信
 */
export const loginCms = data => {
  return request({
    url: '/sendLoginCms',
    method: 'POST',
    data
  })
}

/**
 * 手机号登录
 * @param {*} data
 * @returns
 */
export const loginMobile = data => {
  return request({
    url: '/loginMobile',
    method: 'POST',
    data
  })
}

/**
 * 发送绑定微信
 * @param {*} data
 * @returns
 */
export const sendBindWxCms = data => {
  return request({
    url: '/sendBindWxCms',
    method: 'POST',
    data
  })
}
