export function getToday () {
  const today = new Date()
  const year = today.getFullYear()
  let month = today.getMonth() + 1 // 月份从0开始，需要加1
  if (month < 10) {
    month = '0' + month
  }
  const day = today.getDate()

  return year + '-' + month + '-' + day
}
