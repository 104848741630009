import { publicRoutes, privateRoutes } from '@/router'

export default {
  namespaced: true,
  state: () => ({
    // 路由表： 初始时所有用的路由表
    routes: publicRoutes
  }),
  mutations: {
    /**
     * 作用的就是新增路由
     * @param state
     * @param newRoutes
     */
    setRoutes (state, newRoutes) {
      state.routes = [...publicRoutes, ...newRoutes]
    }
  },
  actions: {
    /**
     * 根据权限数据 来筛选路由
     * @param {*} context
     * @param {*} menus
     */
    filterRouters (context, payload) {
      // 筛选后 需要获取到 通过 addRoute 进行添加的路由表数组
      const {
        menus,
        isSupper
      } = payload
      const routes = []
      if (isSupper) {
        // 超级管理员
        routes.push(...privateRoutes)
      } else {
        // 非超级管理员 需要按照权限进行筛选
        menus.forEach(key => {
          routes.push(...privateRoutes.filter(item => item.name === key))
        })
      }

      // 所有不匹配的路由 全部进入到 404 的路由配置
      // ! 这个配置 必须放到 所有的路由最后
      routes.push({
        path: '/:catchAll(.*)',
        redirect: '/404'
      })
      context.commit('setRoutes', routes)
      return routes
    }
  }
}
