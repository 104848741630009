import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/i18n'
import installElementPlus from './plugins/element'
// 初始化样式表
import './styles/index.scss'
// 导入 svgIcon
import installIcons from '@/icons'
// 导入路由鉴权
import './permission'
// 全局属性
import installFilter from '@/filters'
// 打印
import installDirective from '@/directives'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import VueUeditorWrap from 'vue-ueditor-wrap'
import CodeDiff from 'v-code-diff'
import VuePdfEmbed from 'vue-pdf-embed'

const app = createApp(App)
installElementPlus(app)
installIcons(app)
installFilter(app)
installDirective(app)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(store).use(router).use(CodeDiff).use(i18n).use(VueUeditorWrap).use(VuePdfEmbed, { crossorigin: 'anonymous' }).mount('#app')
