import layout from '@/layout'

export default {
  path: '/reject',
  component: layout,
  redirect: '/reject/masterList',
  name: 'rejectMasterList',
  meta: {
    title: 'reject',
    icon: 'el-icon-files'
  },
  children: [
    {
      path: '/reject/masterList',
      name: 'rejectMasterList',
      component: () => import('@/views/reject/master-list'),
      meta: {
        title: 'rejectMasterList',
        icon: 'el-icon-files'
      }
    }, {
      path: '/reject/MasterEdit/:id',
      name: 'rejectMasterEdit',
      component: () => import('@/views/reject/master-edit'),
      meta: {
        title: 'rejectMasterEdit'
      }
    }
  ]
}
