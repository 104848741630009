import request from '@/utils/request'

/**
 * 没有处理的驳回信息
 * @param {*} data
 * @returns
 */
export const noticeReject = data => {
  return request({
    url: 'noticeReject',
    method: 'POST',
    data
  })
}
