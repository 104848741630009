// 主题
import { MAIN_COLOR, DEFAULT_COLOR } from '@/constant/index'
import { getItem, setItem } from '@/utils/storage'
import variables from '@/styles/variables.module.scss'
export default {
  namespaced: true, // 单独的模块
  state: () => ({
    main_color: getItem(MAIN_COLOR) || DEFAULT_COLOR,
    variables: variables
  }),
  mutations: {
    setMainColor (state, newColor) {
      state.main_color = newColor
      state.variables.menuBg = newColor
      setItem(MAIN_COLOR, newColor)
    }
  },
  actions: {

  }
}
