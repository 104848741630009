import layout from '@/layout'

export default {
  path: '/insurance3',
  component: layout,
  redirect: '/insurance3/orderSubmit5',
  name: 'orderSubmit5',
  meta: {
    title: 'carPlace3',
    icon: 'el-icon-tickets'
  },
  children: [
    {
      path: '/insurance3/orderSubmit5',
      name: 'orderSubmit5',
      component: () => import('@/views/insurance/orderSubmit5'),
      meta: {
        title: 'orderSubmit5',
        icon: 'el-icon-tickets'
      }
    }
  ]
}
