<template>
  <div class="app-main"  ref="container">
    <!-- 带有切换动画 并且具备组件缓存 -->
    <router-view v-slot="{ Component, route }">
      <transition name="fade-transform" mode="out-in">
        <keep-alive>
          <component :is="Component" :key="route.path"/>
        </keep-alive>
      </transition>
    </router-view>
  </div>
</template>

<script setup>
import { watch, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { isTags } from '@/utils/tags'
import { useStore } from 'vuex'
import { generateTitle, watchSwitchLang } from '@/utils/i18n'
import WaterMark from '@/utils/waterMark'
import { getToday } from '@/utils/fun'
const route = useRoute()
const store = useStore()
const container = ref(null)

/**
 * 生成 title
 */
const getTitle = (route) => {
  let title = ''
  if (!route.meta) {
    const pathArr = route.path.split('/')
    title = pathArr[pathArr.length - 1]
  } else {
    title = generateTitle(route.meta.title)
  }

  return title
}

watch(route, (to, from) => {
  // 并不是所有的页面都需要保存生成tag
  if (!isTags(to.path)) return
  const { fullPath, meta, name, params, path, query } = to
  store.commit(
    'app/addTagsViewList',
    {
      fullPath,
      meta,
      name,
      params,
      path,
      query,
      title: getTitle(to)
    },
    {
      immediate: true
    }
  )
})

// 监听国际化 一旦变化 需要title 也是国际化
watchSwitchLang(() => {
  store.getters.tagsViewList.forEach((route, index) => {
    store.commit('app/changeTagsView', {
      index,
      tag: {
        ...route,
        title: getTitle(route)
      }
    })
  })
})

onMounted(() => {
  const today = getToday()
  WaterMark.set(`${store.getters.adminInfo.user_name}  ${today} ${store.getters.adminInfo.last_ip} `, container.value)
})
</script>

<style lang='scss' scoped>
.app-main {
  min-height: calc(100vh - 50px - 43px);
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 104px 20px 20px 20px;
  box-sizing: border-box;
}
</style>
