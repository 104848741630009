<!-- 账套主页 -->
<template>
  <div @click="onToggle">
    <el-tooltip content="首页" >
      <el-icon size="24" color="#5a5e66" class="house" id="guide-account">
        <House />
      </el-icon>
    </el-tooltip>
  </div>
</template>

<script setup>
import {} from 'vue'
import { useStore } from 'vuex'
// 触发事件
const store = useStore()
const onToggle = () => {
  // 回到 账套选择页面
  store.dispatch('admin/accountIndex')
}
</script>

<style lang='scss' scoped>
.house {
  margin-top: 5px;
}
</style>
