import layout from '@/layout'

export default {
  path: '/permission',
  component: layout,
  redirect: '/permission/admin',
  name: 'permissionMark',
  meta: {
    title: 'permission',
    icon: 'el-icon-lock'
  },
  children: [
    {
      path: '/permission/mark',
      name: 'permissionMark',
      component: () => import('@/views/permission/mark/index'),
      meta: {
        title: 'permissionMark',
        icon: 'el-icon-lock'
      }
    }
  ]
}
