<!-- 全屏组件 -->
<template>
  <div @click="onToggle">
    <svg-icon
      :icon="isFullScreen ? 'exit-fullscreen' : 'fullscreen'"
      id="guide-full"
    ></svg-icon>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue'
import screenfull from 'screenfull'
// 是否是全屏
const isFullScreen = ref(false)

// 触发事件
const onToggle = () => {
  screenfull.toggle()
}

// 监听
const change = () => {
  isFullScreen.value = screenfull.isFullscreen
}

// on 绑定监听
onMounted(() => {
  screenfull.on('change', change)
})

// off 取消监听
onUnmounted(() => {
  screenfull.off('change', change)
})
</script>

<style lang='scss' scoped>
</style>
