import layout from '@/layout'

export default {
  path: '/reject',
  component: layout,
  redirect: '/reject/invoice',
  name: 'rejectInvoice',
  meta: {
    title: 'reject',
    icon: 'el-icon-files'
  },
  children: [
    {
      path: '/reject/invoice',
      name: 'rejectInvoice',
      component: () => import('@/views/reject/invoice'),
      meta: {
        title: 'rejectInvoice',
        icon: 'el-icon-files'
      }
    }
  ]
}
