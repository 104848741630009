import layout from '@/layout'

export default {
  path: '/insurance2',
  component: layout,
  redirect: '/insurance2/orderSubmit2',
  name: 'orderSubmit2',
  meta: {
    title: 'carPlace2',
    icon: 'el-icon-tickets'
  },
  children: [
    {
      path: '/insurance2/orderSubmit2',
      name: 'orderSubmit2',
      component: () => import('@/views/insurance/orderSubmit2'),
      meta: {
        title: 'orderSubmit2',
        icon: 'el-icon-tickets'
      }
    }
  ]
}
