// import layout from '@/layout'

// export default {
//   path: '/policy',
//   component: layout,
//   redirect: '/policy/index',
//   children: [
//     {
//       path: '/policy/index',
//       name: 'policy',
//       component: () => import('@/views/policy/index'),
//       meta: {
//         title: 'policy',
//         icon: 'el-icon-aim'
//       }
//     }
//   ]
// }
import layout from '@/layout'

export default {
  path: '/other',
  component: layout,
  redirect: '/other/policy',
  name: 'policyIndex',
  meta: {
    title: 'policy',
    icon: 'el-icon-document'
  },
  children: [
    {
      path: '/policy/index',
      name: 'policyIndex',
      component: () => import('@/views/policy/index'),
      meta: {
        title: 'policyIndex',
        icon: 'el-icon-document'
      }
    }
  ]
}
