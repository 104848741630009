import layout from '@/layout'

export default {
  path: '/bossWork',
  component: layout,
  redirect: '/bossWork/person/report',
  name: 'BossWorkPersonReport',
  meta: {
    title: 'bossWork',
    icon: 'el-icon-document'
  },
  children: [
    {
      path: '/bossWork/person/report',
      name: 'BossWorkPersonReport',
      component: () => import('@/views/person/report/index'),
      meta: {
        title: 'BossWorkPersonReport',
        icon: 'el-icon-document'
      }
    }
  ]
}
