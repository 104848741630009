import { setItem } from '@/utils/storage'

export default {
  namespaced: true,
  state: () => ({
    currentAccountId: 0,
    currentAccountName: ''
  }),
  mutations: {
    setCurrentAccountId (state, data) {
      console.log(data)
      state.currentAccountId = data.id
      state.currentAccountName = data.name
      setItem('currentAccountId', data.id)
      setItem('currentAccountName', data.name)
    }
  }
}
