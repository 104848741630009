<template>
<router-view v-if="isRouterAlive" />
</template>

<script setup>
import { useStore } from 'vuex'
import { generateNewStyle, writeNewStyle } from './utils/theme'
import { watchSwitchLang } from '@/utils/i18n'
import { provide, ref, nextTick } from 'vue'

const isRouterAlive = ref(true)
const reload = () => {
  isRouterAlive.value = false
  nextTick(() => {
    isRouterAlive.value = true
  })
}
provide('reload', reload)

// 获取主题 加载主题css
const store = useStore()
generateNewStyle(store.getters.mainColor).then(newStyle => {
  writeNewStyle(newStyle)
})

watchSwitchLang(() => {
  if (store.getters.token) {
    // store.dispatch('user/getUserInfo')
  }
})
</script>

<style></style>
