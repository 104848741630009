import layout from '@/layout'

export default {
  path: '/insurance4',
  component: layout,
  redirect: '/insurance4/orderSubmit6',
  name: 'orderSubmit6',
  meta: {
    title: 'carPlace4',
    icon: 'el-icon-tickets'
  },
  children: [
    {
      path: '/insurance4/orderSubmit6',
      name: 'orderSubmit6',
      component: () => import('@/views/insurance/orderSubmit6'),
      meta: {
        title: 'orderSubmit6',
        icon: 'el-icon-tickets'
      }
    }
  ]
}
