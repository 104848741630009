import layout from '@/layout'

export default {
  path: '/car',
  component: layout,
  redirect: '/car/page2',
  name: 'carPage2',
  meta: {
    title: 'car',
    icon: 'el-icon-document'
  },
  children: [
    {
      path: '/car/page2',
      name: 'carPage2',
      component: () => import('@/views/car/page2/page2'),
      meta: {
        title: 'carPage2',
        icon: 'el-icon-document'
      }
    }
  ]
}
