import layout from '@/layout'

export default {
  path: '/insurance2',
  component: layout,
  redirect: '/insurance2/orderSubmit3',
  name: 'orderSubmit3',
  meta: {
    title: 'carPlace2',
    icon: 'el-icon-tickets'
  },
  children: [
    {
      path: '/insurance2/orderSubmit3',
      name: 'orderSubmit3',
      component: () => import('@/views/insurance/orderSubmit3'),
      meta: {
        title: 'orderSubmit3',
        icon: 'el-icon-tickets'
      }
    }
  ]
}
