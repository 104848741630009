import { createStore } from 'vuex'
import app from './modules/app.js'
import admin from './modules/admin.js'
import account from './modules/account.js'
import theme from './modules/theme.js'
import permission from './modules/permission.js'
import getters from './getters'
export default createStore({
  getters,
  modules: {
    account,
    app,
    theme,
    permission,
    admin
  }
})
