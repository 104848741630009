import layout from '@/layout'

export default {
  path: '/insurance1',
  component: layout,
  redirect: '/insurance1/orderSubmit1',
  name: 'orderSubmit1',
  meta: {
    title: 'carPlace1',
    icon: 'el-icon-tickets'
  },
  children: [
    {
      path: '/insurance1/orderSubmit1',
      name: 'orderSubmit1',
      component: () => import('@/views/insurance/orderSubmit1'),
      meta: {
        title: 'orderSubmit1',
        icon: 'el-icon-tickets'
      }
    }
  ]
}
